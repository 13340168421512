import React from 'react'
import { IOverviewInfo } from '../../models/oracle'

function Item({ name, value }) {
  return (
    <div className="flex flex-col justify-center items-center w-1/2">
      <div className="text-sm text-secondary">{name}</div>
      <div className="text-2xl">{value}</div>
    </div>
  )
}

export default function Overview({ data }: { data: IOverviewInfo }) {
  return (
    <div className="bg-card mb-2 md:mb-0 md:mr-2 w-full md:flex-0 md:w-2/5 py-2 px-4 divide-y flex flex-col">
      <div className="text-lg mb-2">Overview</div>
      <div className="pt-2 flex-1 flex flex-row justify-between items-stretch px-4 flex-wrap">
        <Item name="Last Block" value={data?.latestBlock} />
        <Item name="Total Aggregators" value={data?.aggregatorNum} />
        <Item name="Daily Submissions" value={data?.dailySub} />
        <Item name="All Submissions" value={data?.allSub} />
      </div>
    </div>
  )
}
