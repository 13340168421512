import React from 'react'
import logo from '../../assets/images/logo_text.svg'
import { NavLink } from 'react-router-dom'

export default function Header() {
  return (
    <div className="bg-card mb-6 shadow">
      <div className="max-w-6xl mx-auto flex flex-row justify-start items-center h-14">
        <NavLink to="/oracles" className="mr-4">
          <img className="h-8" src={logo} alt="" />
        </NavLink>
      </div>
    </div>
  )
}
