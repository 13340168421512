import React from 'react'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'
import moment from 'moment'

export default function SubmissionChart({ data }: { data: { ts: number, value: number }[] }) {
  const option = {
    textStyle: {
      fontFamily: 'Open Sans',
    },
    grid: {
      top: '5%',
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        splitLine: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#E8E9EB' } },
        axisLabel: {
          textStyle: {
            margin: 20,
            color: '#888',
          },
        },
        data: data.map(i => moment.unix(i.ts).format('MM-DD')),
      },
    ],
    yAxis: [
      {
        splitLine: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#E8E9EB' } },
        axisLabel: {
          textStyle: {
            color: '#888',
          },
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#1B2027dd',
      borderColor: '#1B2027',
      formatter: function (params) {
        return `
<div class="text-sm">
  <div>Timestamp: <span class="text-secondary">${moment.unix(params[0].data.time).format('YYYY-MM-DD')}</span></div>
  <div>Submissions: <span class="text-secondary">${params[0].value}</span></div>
</div>
`
      },
    },
    series: [
      {
        type: 'line',
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          normal: {
            color: '#F2264E',
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                { offset: 0, color: 'rgba(242, 38, 78, 0.2)' },
                { offset: 0.6, color: 'rgba(242, 38, 78, 0)' },
              ],
              false,
            ),

            shadowColor: 'rgba(242, 38, 78, 0.9)',
            shadowBlur: 20,
          },
        },
        data: data.map(i => ({
          value: i.value,
          time: i.ts,
        })),
      },
    ],
  }

  return (
    <div className="bg-card mr-2 flex-1 py-2 px-4 divide-y">
      <div className="text-lg mb-2">Daily Submissions</div>
      <div className="pt-2">
        <ReactECharts
          option={option}
          style={{ height: '200px', width: '100%' }}
        />
      </div>
    </div>
  )
}
