export default function parseAmount(amount: any, unit: any): [any, any, any] {
  if (unit === 'null') {
    return ['', amount, '']
  }

  if (unit === '$') {
    return ['$', amount, '']
  }

  return ['', amount, unit ?? '']
}
