import React, { useCallback, useState } from 'react'
import { CheckCircle, Copy } from 'react-feather'
import { IBasicInfo } from '../../models/oracle'
import moment from 'moment'
import copyToClipboard from 'copy-to-clipboard'
import parseAmount from '../../utils/parseAmount'

function InfoItem({ name, value }) {
  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="text-secondary">{name}</div>
      <div>{value}</div>
    </div>
  )
}


export default function BasicInfo({ data }: { data: IBasicInfo }) {
  const [isCopying, setIsCopying] = useState(false)

  const onCopy = useCallback(() => {
    copyToClipboard(data.contract)
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 1000)
  }, [data.contract])

  const [prefix, amount, suffix] = parseAmount(data.latestAnswer, data.unit)

  return (
    <div className="bg-card text-white rounded shadow mb-4 md:mb-0 md:w-1/3 text-sm">
      <div className="p-4">
        <div className="text-secondary">Trusted answer</div>
        <div className="flex flex-row items-end my-4 border-b pb-4">
          <div className="text-xl mr-1">{prefix}</div>
          <div className="text-4xl mr-1">{amount}</div>
          <div className="text-xl mr-1">{suffix}</div>
        </div>
        <div className="mt-2 flex flex-row">
          <InfoItem name="Deviation threshold" value={`${data.threshold}%`} />
          <InfoItem name="Heartbeat" value={data.heartbeat} />
        </div>
        <div className="mt-2 flex flex-row items-stretch">
          <InfoItem name="Oracle responses" value={`${data.oracleResNum}/${data.oracleNum}`} />
          <InfoItem name="Last update" value={moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm')} />
        </div>
      </div>
      <div className="bg-primary p-4 rounded-b">
        <div className="flex flex-row items-center">
          <div className="mr-1">Contract address</div>
          {
            isCopying ? <CheckCircle className="cursor-pointer" size={14} /> :
              <Copy className="cursor-pointer" size={14} onClick={onCopy} />
          }
        </div>
        <div>{data.contract}</div>
      </div>
    </div>
  )
}
