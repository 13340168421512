import React from 'react'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'
import useFetch from '../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import moment from 'moment'

export default function HistoryChart() {
  const { symbol, network = '' } = useParams<{ symbol: string, network: string }>()
  const networkKey = network.toLowerCase().replace(/[\W_]+/g, '')
  console.log(networkKey)
  let path = {
    'bsctestnet': '/subgraphs-bsctestnet/name/phoenix/oracle',
    'sepolia': '/subgraphs-sepolia/name/phoenix/oracle',
  }[networkKey]
  const { data } = useFetch<{ hourlyCandles: { closeTimestamp: number, averagePrice: number }[] } | undefined>(path, 'post', `{"query": "{

hourlyCandles(first:500, orderBy:openTimestamp, where:{openTimestamp_gt:${moment().subtract(14, 'day').unix()},openTimestamp_lt:${moment().unix()},assetPair:\\"${symbol}\\"}) {
  openTimestamp
  closeTimestamp
  averagePrice
}

}"}`.replaceAll('\r', '').replaceAll('\n', ''))

  const times = data?.hourlyCandles?.map(i => i.closeTimestamp)
  const prices = data?.hourlyCandles?.map(i => i.averagePrice / 100000000)
  const option = {
    textStyle: {
      fontFamily: 'Open Sans',
    },
    borderRadius: 8,
    title: {
      text: 'History',
      left: '3%',
      top: '3%',
      textStyle: {
        fontSize: '20',
        color: '#ffffff',
        fontWeight: 'normal',
      },
    },
    grid: {
      top: '15%',
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#1B2027dd',
      borderColor: '#1B2027',
      formatter: function (params) {
        return `
<div class="text-sm">
  <div>Timestamp: <span class="text-secondary">${moment.unix(params[0].name).format('YYYY-MM-DD HH:mm')}</span></div>
  <div>Trusted answer: <span class="text-secondary">${params[0].value}</span></div>
</div>
`
      },
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        splitLine: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#E8E9EB' } },
        axisLabel: {
          formatter: (value) => {
            return moment.unix(value).format('MM-DD HH:mm')
          },
          textStyle: {
            margin: 20,
            color: '#888',
          },
        },
        data: times,
      },
    ],
    yAxis: [
      {
        splitLine: {
          show: false,
        },
        axisLine: { show: true, lineStyle: { color: '#E8E9EB' } },
        axisLabel: {
          textStyle: {
            color: '#888',
          },
        },
      },
    ],
    series: [
      {
        type: 'line',
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          normal: {
            color: '#F2264E',
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                { offset: 0, color: 'rgba(242, 38, 78, 0.2)' },
                { offset: 0.6, color: 'rgba(242, 38, 78, 0)' },
              ],
              false,
            ),

            shadowColor: 'rgba(242, 38, 78, 0.9)',
            shadowBlur: 20,
          },
        },
        data: prices,
      },
    ],
  }

  return (
    <div className="md:ml-4 bg-card rounded flex-1 shadow">
      {data && <ReactECharts option={option} />}
    </div>
  )
}
