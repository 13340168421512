import React from 'react'
import { ChevronRight } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Spin } from 'antd'
import moment from 'moment'
import formatSymbol from '../../utils/formatSymbol'
import parseAmount from '../../utils/parseAmount'

export default function OracleTable({ data }: { data?: any[] }) {
  return (
    <Spin spinning={!data}>
      <div className="bg-card border-l border-b border-r shadow divide-y rounded-br rounded-bl">
        <div className="flex flex-row justify-between py-4 px-4 text-secondary">
          <div className="flex-1">Name</div>
          <div className="flex-1">Trusted Answer</div>
          <div className="flex-1">Network</div>
          <div className="flex-1">Last Update</div>
        </div>
        {data?.map((i, index) => {
          const [prefix, amount, suffix] = parseAmount(i.latestAnswer, i.unit)
          return (
            <NavLink className="flex flex-row justify-between items-center py-4 px-4 hover:bg-card-hover hover:text-white relative"
                     to={`/oracles/${i.network}/${i.symbol}`} key={index}>
              <div className="flex-1 flex flex-row">
                <div>{`${formatSymbol(i.symbol)}`}</div>
              </div>
              <div className="flex-1 flex flex-row">
                <div>{`${prefix}${amount}${suffix}`}</div>
              </div>
              <div className="flex-1 flex flex-row">
                <div>{i.network}</div>
              </div>
              <div className="flex-1 flex flex-row">
                <div>{moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm')}</div>
              </div>
              <ChevronRight className="hidden md:block absolute right-0 mx-4" />
            </NavLink>
          )
        })}
      </div>
    </Spin>
  )
}
