import React from 'react'
import { IOraclesStatus } from '../../models/oracle'
import moment from 'moment'
import parseAmount from '../../utils/parseAmount'

export default function OracleList({ data }: { data: IOraclesStatus[] }) {
  return (
    <div>
      <div className="text-2xl pt-8 px-2 md:px-0">
        Oracles
      </div>
      <div className="flex flex-col md:flex-row items-stretch flex-wrap">
        {data.map((i, index) => {
            const [prefix, amount, suffix] = parseAmount(i.answer, i.unit)
            return (
              <div className={`w-full md:w-1/2 min-w-45 mt-3 ${index % 2 === 0 ? 'md:pr-2' : 'md:pl-2'}`} key={`Oracle${index}`}>
                <div className={`p-4 divide-y bg-card rounded`}>
                  <div className="mb-4">
                    <div className="flex flex-row justify-between items-center">
                      <div className="text-sm">
                        <div className={`${i.responded ? 'text-white' : 'text-secondary'}`}>{i.responded ? 'Responded' : 'Not Responded'}</div>
                        <div className="text-sx break-all text-secondary">{i.oracleAddress}</div>
                      </div>
                      <div className="text-right">
                        <div className="text-lg">{prefix}{amount}{suffix}</div>
                        <div className="text-sm text-secondary">Latest answer</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center pt-3">
                    <div className="text-secondary text-sm">{moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm')}</div>
                    <a href={i.detailURL} target="_blank" rel="noreferrer" type="primary" className="bg-primary px-2 py-1 hover:text-white hover:opacity-80 rounded-sm">Detail</a>
                  </div>
                </div>
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}
