import React, { useEffect, useState } from 'react'
import { ChevronLeft } from 'react-feather'
import { NavLink, useParams } from 'react-router-dom'
import HistoryChart from './HistoryChart'
import BasicInfo from './BasicInfo'
import OracleList from './OracleList'
import useFetch from '../../hooks/useFetch'
import { IBasicInfo, IOraclesStatus } from '../../models/oracle'
import { Spin } from 'antd'
import formatSymbol from '../../utils/formatSymbol'

export default function Oracle() {
  const { symbol, network } = useParams<{ symbol: string, network: string }>()

  const { data: basicInfoData } = useFetch<IBasicInfo>(`/api/queryBasicInfo?symbol=${symbol}&network=${network}`)
  const { data: oraclesStatusData } = useFetch<{ records: IOraclesStatus[], oracleCount: number, respondedCount: number }>(`/api/queryOraclesStatus?symbol=${symbol}&network=${network}`)
  const [info, setInfo] = useState<IBasicInfo | undefined>()
  const [oracles, setOracles] = useState<IOraclesStatus[] | undefined>()

  useEffect(() => {
    if (basicInfoData && oraclesStatusData) {
      setInfo({
        ...basicInfoData,
        oracleResNum: oraclesStatusData.respondedCount,
        oracleNum: oraclesStatusData.oracleCount,
      })
      setOracles(oraclesStatusData.records.map(i => ({
        ...i,
        detailURL: `${basicInfoData.detailURL}/${i.oracleAddress}`,
        unit: basicInfoData.unit,
      })))
    }
  }, [basicInfoData, oraclesStatusData, setInfo])

  return (
    <div className="max-w-6xl mx-auto module">
      <div className="flex items-center justify-start">
        <NavLink to="/oracles" className="pr-4"><ChevronLeft /></NavLink>
        <div className="text-2xl py-4">
          {`${formatSymbol(symbol)}`}
        </div>
      </div>
      <Spin spinning={!info}>
        <div className="w-full flex flex-col md:flex-row justify-between items-stretch">
          {
            info && <>
              <BasicInfo data={info} />
              <HistoryChart />
            </>
          }
        </div>
        {
          oracles && <OracleList data={oracles} />
        }
      </Spin>
    </div>
  )
}
