import useSWR from 'swr'

const getFetcher = async (url) => {
  const res = await fetch(url)
  return res.json()
}

const postFetcher = async (url, formData) => {
  const res = await fetch(url, {
    method: 'POST',
    body: formData,
  })
  return res.json()
}


export default function useFetch<T>(url, method: 'post' | 'get' = 'get', form: any = undefined) {
  const fetcher = method === 'get' ? getFetcher : postFetcher as any
  const { data, error } = useSWR<{ data: T }>(form ? [url, form] : url, fetcher)

  return {
    data: data?.data,
    error,
  }
}
