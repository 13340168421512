import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Header from './components/header'
import Oracle from '../src/pages/oracle'
import Oracles from '../src/pages/oracles'


export default function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact strict path="/oracles" component={Oracles} />
        <Route exact strict path="/oracles/:network/:symbol" component={Oracle} />
        <Route>
          <Redirect to="/oracles" />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
