import React, { StrictMode, Suspense } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import ReactDOM from 'react-dom'
import { ChainId, DAppProvider } from '@usedapp/core'
import './assets/styles/antd.less'
import './assets/styles/index.css'
import App from './App'
import store from './state'

const config = {
  readOnlyChainId: ChainId.BSCTestnet,
}

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={null}>
      <DAppProvider config={config}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </DAppProvider>
    </Suspense>
  </StrictMode>
  , document.getElementById('root'),
)
