import React, { useMemo } from 'react'
import { Spin, Tabs } from 'antd'
import Overview from './Overview'
import OracleTable from './OracleTable'
import SubmissionChart from './SubmissionChart'
import useFetch from '../../hooks/useFetch'
import { IOverviewInfo } from '../../models/oracle'
import moment from 'moment'

const { TabPane } = Tabs

export default function Oracles() {
  const { data } = useFetch<IOverviewInfo>('/api/queryOverviewInfo')

  const { data: oracles1 } = useFetch<{ records: any[] }>(`/api/queryAllOracleByType?type=1`)
  const { data: oracles2 } = useFetch<{ records: any[] }>(`/api/queryAllOracleByType?type=2`)
  const { data: oracles3 } = useFetch<{ records: any[] }>(`/api/queryAllOracleByType?type=3`)
  const { data: oracles4 } = useFetch<{ records: any[] }>(`/api/queryAllOracleByType?type=4`)

  const otherOracles = useMemo(() => {
    if (oracles3 && oracles4) {
      return [...oracles3.records, ...oracles4.records]
    }
    return []
  }, [oracles3, oracles4])

  const submissionData = useMemo(() => {
    if (!data) {
      return []
    }
    const today = moment().endOf('day')
    return data.historySubNum.split(',').map((i) => {
      today.subtract(1, 'day')
      return {
        ts: today.unix(),
        value: parseFloat(i),
      }
    }).reverse()
  }, [data])

  const tabs = [
    ['1', 'Stock'],
    ['2', 'Crypto'],
    ['0', 'Others'],
  ]

  return (
    <div className="max-w-6xl mx-auto pb-20">
      <Spin spinning={!data}>
        <div className="flex flex-col md:flex-row justify-between items-stretch">
          {
            data && <>
              <Overview data={data} />
              <SubmissionChart data={submissionData} />
            </>
          }
        </div>
      </Spin>
      <div className="mt-4">
        <Tabs type="card">
          {
            tabs.map(i => (
              <TabPane tab={i[1]} key={i[0]}>
                <OracleTable data={{ '1': oracles1?.records, '2': oracles2?.records }[i[0]] ?? otherOracles} />
              </TabPane>
            ))
          }
        </Tabs>
      </div>
    </div>
  )
}
